<template>
    <v-card
        tile
        :loading="isLoading"
        min-height="300px"
        class="capacity"
    >
        <div class="d-md-flex align-center pa-4">
            <div class="capacity-filter">
                <v-select
                    v-model="mode"
                    hide-details
                    dense
                    outlined
                    full-width
                    :items="modeSelectItems"
                >
                    <template #prepend-inner>
                        <w-icon dense value="PERIOD" class="mdi-view-carousel"/>
                    </template>
                </v-select>
            </div>
            <div class="capacity-filter pl-md-4 pt-md-0 pt-4">
                <v-select
                    v-model="service.filterBy.level"
                    :placeholder="$trans('Level')"
                    outlined
                    clearable
                    dense
                    hide-details
                    full-width
                    :items="levelsSelectItems"
                    item-text="title"
                    item-value="uuid"
                    @click:clear="service.filterBy.group = null"
                >
                    <template #prepend-inner>
                        <w-icon dense value="LEVEL" class="prepend-inner-icon"/>
                    </template>
                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item dense v-bind="attrs" v-on="on" style="height: 2.5rem">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                <v-list-item-subtitle class="caption">{{
                                        item.school.title
                                    }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>
            </div>
            <div v-if="mode === 'capacity'" class="capacity-filter pl-md-4 pt-md-0 pt-4">
                <v-select
                    v-model="service.filterBy.studyPeriod"
                    :placeholder="$trans('Study period')"
                    hide-details
                    dense
                    outlined
                    clearable
                    full-width
                    :items="studyPeriods"
                >
                    <template #prepend-inner>
                        <w-icon dense value="PERIOD" class="prepend-inner-icon"/>
                    </template>
                </v-select>
            </div>
            <div v-if="mode === 'capacity'" class="capacity-filter pl-md-4 pt-md-0 pt-4">
                <v-select
                    v-model="service.filterBy.teacher"
                    v-if="teacherSelectItems.length > 0"
                    :placeholder="$trans('Teacher')"
                    outlined
                    clearable
                    dense
                    hide-details
                    full-width
                    :items="teacherSelectItems"
                    item-text="last_name"
                    item-value="uuid"
                    @click:clear="service.filterBy.group = null"
                >
                    <template #prepend-inner>
                        <w-icon dense value="TEACHER" class="prepend-inner-icon"/>
                    </template>
                    <template #selection="{ item }">
                        <div class="v-select__selection v-select__selection--comma">{{ item | fullName }}</div>
                    </template>
                    <template #item="{ item, on, attr }">
                        <v-list-item v-on="on" v-bind="attr">
                            <v-list-item-content>
                                <v-list-item-title>{{ item | fullName }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>
            </div>
        </div>

        <v-divider></v-divider>
        <div v-if="!isLoading" class="capacity-table-wrapper">
            <v-simple-table class="capacity-table">
                    <thead>
                    <tr
                        v-for="(row, index) in header"
                        :key="`thead-tr-${index}`"
                    >
                        <td
                            v-for="col in row"
                            :key="col.index"
                            v-bind="col.props"
                        >
                            <div v-if="col.translate">
                                {{ $trans(col.value) }}
                            </div>
                            <div v-else>
                                {{ col.value }}
                            </div>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(row, index) in rows"
                        :key="`thead-tr-${index}`"
                        :class="index < (rows.length - 1) ? 'hover' : 'tr-total'"
                    >
                        <td
                            v-for="col in row.children"
                            :key="col.index"
                            v-bind="col.props"
                        >
                            <template v-if="col.translate">
                                {{ $trans(col.value) }}
                            </template>
                            <template v-else>
                                {{ col.value }}
                            </template>
                        </td>
                    </tr>
                    </tbody>
            </v-simple-table>
        </div>
    </v-card>
</template>

<script>
import { getCapacity } from '@/apps/school/api/capacity'
import CapacityService from '@/apps/school/components/Capacity/Services/CapacityService'
import { PERIOD, TEACHER, LEVEL, GROUP } from '@/widgets/components/WIcon/icons'

export default {
    name: 'CapacityTable',
    props: {
        payload: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            icons: {
                PERIOD,
                TEACHER,
                LEVEL,
                GROUP
            },
            isLoading: false,
            service: new CapacityService(),
            mode: 'capacity'
        }
    },
    computed: {
        modeSelectItems() {
            return [
                {
                    text: this.$trans('Capacity calculation'),
                    value: 'capacity'
                },
                {
                    text: this.$trans('Capacity defined'),
                    value: 'capacity-amounts'
                }
            ]
        },
        levelsSelectItems() {
            const sortable = this.service.definedLevels

            return sortable.sort((a, b) => (a.title > b.title) ? 1 : -1)
        },
        teacherSelectItems() {
            return this.service.teachers
        },
        header() {
            return this.service.header()
        },
        rows() {
            return this.service.rows()
        },
        studyPeriods() {
            const studyPeriods = this.$store.getters['school/studyPeriods']
                .map(o => Object.assign(o.values, { uuid: o.uuid }))

            if (studyPeriods.length === 0) {
                return []
            }

            const periods = []

            studyPeriods.forEach(o => {
                periods.push({
                    text: `${o.label}`,
                    value: o.uuid
                })
            })

            return periods
        }
    },
    watch: {
        mode(value) {
            this.service.resetFilter()
            this.fetchCapacity({
                ...this.payload,
                mode: value
            })
        }
    },
    mounted() {
        this.mode = this.payload.mode || 'capacity'
        this.fetchCapacity(this.payload)
    },
    methods: {
        fetchCapacity(payload) {
            this.isLoading = true

            payload = payload || {}
            payload.mode = payload.mode || this.mode

            getCapacity(payload)
                .then(response => {
                    this.service.setLevels(response.data.levels)
                    this.service.setLessonTypeGroups(response.data.lesson_type_groups)
                    this.service.setLessonTypes(response.data.lesson_types)
                    this.service.setCourses(response.data.courses)
                    this.service.setCapacities(response.data.data)
                    this.service.setTeachers(response.data.teachers)

                    this.isLoading = false
                })
        },
        onRowClick() {

        }
    }
}
</script>

<style lang="scss">
$color: rgba(94, 86, 105, 0.87);
$active-color: var(--v-primary-base);
$border-color: rgba(94, 86, 105, 0.14) !important;
$border: 1px solid $border-color;
$background: #fafafa;

.capacity {
    overflow: hidden;
}

.capacity-table-wrapper {
    overflow: auto;
}

.capacity-filter {

    @media screen and (min-width: 960px) {
        max-width: 250px;
    }

    .prepend-inner-icon {
        margin-top: 3px;
        margin-right: 4px;
    }
}

.capacity-table {
    min-width: 100%;
    font-size: small;
    color: $color !important;

    td, th {
        // padding: 6px 4px;
        border-bottom: $border;
        border-left: $border;
    }

    .th-number, .td-number {
        border-left: none !important;
    }

    .th-lesson-type-group,
    .th-lesson-type--lt,
    .td-lesson-type--lt,
    .th-total,
    .td-total {
        border-left-width: 2px !important;
    }

    thead {
        user-select: none;
        // color: $color !important;
        // background-color: $background !important;

        tr {
            td {
                text-align: center !important;
                font-size: 0.7rem !important;

                &.th-lesson-type {
                    font-size: 0.65rem !important;
                }

                &.text-vertical {
                    div {
                        writing-mode: vertical-rl;
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        transform: rotate(180deg);
                        display: inline-block;
                        height: 150px !important;
                    }
                }
            }
        }
    }

    tbody {

        font-size: 0.75rem !important;

        tr {

            transition: all 0.2s;

            &:nth-child(even) {
                // background: $background
            }

            &.tr-total {
                // background: #f3f3f3;
            }

            &.hover:hover, &.hover:active {
                td {
                    color: $active-color !important;
                    // border-bottom-color: var(--v-primary-lighten4) !important;
                }
            }

            td {
                &.td-course {
                    max-width: 200px;
                }

                &.td-level {
                    text-align: left;
                    // max-width: 70px;
                }

                &.td-group {
                    text-align: left;
                    // max-width: 70px;
                }
            }
        }
    }
}
</style>
