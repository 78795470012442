<template>
    <v-card
        outlined
    >
        <capacity-table
            :payload="payload"
        />
    </v-card>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import CapacityTable from '@/apps/school/components/Capacity/CapacityTable'

export default {
    name: 'DepartmentsCapacity',
    components: { CapacityTable },
    computed: {
        locale() {
            return this.$store.getters.locale
        },
        payload() {
            return {
                department: this.$route.params.uuid
            }
        },
        studyPeriods() {
            const studyPeriods = this.$store.getters['school/studyPeriods']
                .map(o => {
                    return { ...o.values, uuid: o.uuid }
                })

            if (studyPeriods.length === 0) {
                return []
            }

            const periods = []

            periods.push({
                text: this.$trans('Common') + `, ${moment(_.first(studyPeriods).begin).format('DD.MM.YYYY')} - ${moment(_.last(studyPeriods).end).format('DD.MM.YYYY')}`,
                value: 'all'
            })

            studyPeriods.forEach(o => {
                periods.push({
                    text: `${o.label}, ${moment(o.begin).format('DD.MM.YYYY')} - ${moment(o.end).format('DD.MM.YYYY')}`,
                    value: o.uuid
                })
            })

            return periods
        }
    },
    data() {
        return {
            period: 'all'
        }
    }
}
</script>

<style lang="scss">

</style>
