/* eslint-disable */
import _ from 'lodash'

export default class CapacityService {

    constructor() {
        this.levels = []
        this.definedLevels = []
        this.courses = []
        this.teachers = []
        this.capacities = []
        this.lesson_types = []
        this.lesson_type_groups = []
        this.headerOfLessonTypes = []
        this.filterBy = {
            studyPeriod: null,
            level: null,
            group: null,
            teacher: null
        }
    }

    resetFilter() {
        this.filterBy = {
            studyPeriod: null,
            level: null,
            group: null,
            teacher: null
        }
    }

    setFilter(options) {
        this.filterBy = Object.assign({}, options)
    }

    setLevels(levels) {
        this.levels = _.concat([], levels)
    }

    setCourses(courses) {
        this.courses = courses
    }

    setTeachers(teachers) {
        this.teachers = teachers
    }

    setCapacities(data) {
        this.capacities = data
    }

    setLessonTypeGroups(lessonTypeGroups) {
        this.lesson_type_groups = _.concat([], lessonTypeGroups)
    }

    setLessonTypes(lessonTypes) {
        this.lesson_types = _.groupBy(lessonTypes, 'group')
    }

    header() {
        const header = []

        const primary = [
            {
                props: {
                    rowspan: 2,
                    class: 'th-number font-weight-black'
                },
                index: `thead-th-#`,
                value: '#'
            },
            {
                props: {
                    rowspan: 2,
                    class: 'th-course font-weight-black'
                },
                index: `thead-th-Course`,
                translate: true,
                value: 'Course'
            },
            {
                props: {
                    rowspan: 2,
                    class: 'th-level font-weight-black'
                },
                index: `thead-th-levels`,
                value: 'Level',
                translate: true,
            },
            {
                props: {
                    rowspan: 2,
                    class: 'th-group font-weight-black'
                },
                index: `thead-th-groups`,
                value: 'Groups',
                translate: true,
            },
            {
                props: {
                    rowspan: 2,
                    class: 'th-students-amount text-vertical text-truncate font-weight-black'
                },
                index: `thead-th-Students-Amount`,
                value: 'Students amount',
                translate: true,
            }
        ]

        const secondary = []
        const _lessonTypes = this.lesson_types

        this.lesson_type_groups.forEach((lessonTypeGroup, i) => {
            primary.push({
                props: {
                    colspan: _lessonTypes[lessonTypeGroup.value].length,
                    class: 'th-lesson-type-group font-weight-bold'
                },
                index: `thead-th-${i}`,
                value: lessonTypeGroup.label
            })

            _lessonTypes[lessonTypeGroup.value].forEach((lessonType, j, arr) => {
                const ltClass = j === 0 ? 'th-lesson-type--lt' : ''
                const rtClass = j === arr.length - 1 ? 'th-lesson-type--rt' : ''

                secondary.push({
                    props: {
                        class: `th-lesson-type ${ltClass}${rtClass} text-vertical`
                    },
                    index: `thead-th-${i}-${j}`,
                    lesson_type_uuid: lessonType.uuid,
                    lt: j === 0,
                    rt: j === arr.length - 1,
                    value: lessonType.description ?  lessonType.description : lessonType.title
                })
            })
        })

        primary.push({
            props: {
                rowspan: 2,
                class: 'th-total font-weight-black'
            },
            index: `thead-th-total`,
            value: 'Total',
            translate: true,
        })

        header.push(primary)
        header.push(secondary)

        this.headerOfLessonTypes = secondary

        return header
    }

    rows() {
        const data = []
        const courses = this.courses
        const capacityC = _.groupBy(this.capacities, 'course_uuid')
        const capacityCL = {}

        for (const courseUuid in capacityC) {
            capacityCL[courseUuid] = _.groupBy(capacityC[courseUuid], 'lesson_type_uuid')
        }

        const coursesTotalKey = 'coursesTotal'
        let _lessonTypesTotal = {}

        for (let index = 0; index < courses.length; index++) {
            const teachers = this.teachers
            const course = courses[index]
            const courseUuid = course.uuid
            const _groups = _.uniqBy(_.flattenDeep(_.map(capacityC[courseUuid], o => o.groups), 1), 'uuid')
            const _groupsUuids = _.map(_groups, o => o.uuid)
            const _levelsUuids = _.uniq(_.map(_groups, o => o.level_uuid))
            const _levels = _.filter(this.levels, o => _.includes(_levelsUuids, o.uuid))

            this.definedLevels = _.unionWith(this.definedLevels, _levels, _.isEqual)

            // Filter by level
            const filterByLevel = this.filterBy.level
            if(filterByLevel && !_.includes(_levelsUuids, filterByLevel)) {
                continue
            }

            // Filter by group
            const filterByGroup = this.filterBy.group
            if(filterByGroup && !_.includes(_groupsUuids, filterByGroup)) {
                continue
            }

            // Filter by teacher
            const filterByTeacher = this.filterBy.teacher
            if(filterByTeacher) {
                const includesTeacher = _.filter(capacityC[courseUuid], o => o.teacher_uuid === filterByTeacher)

                if(includesTeacher.length === 0) {
                    continue
                }
            }

            const row = {
                course: course,
                children: []
            }

            row.children.push({
                props: {
                    class: 'td-number text-center'
                },
                index: `#-${index}`,
                value: index + 1
            })

            row.children.push({
                props: {
                    class: 'td-course font-weight-semibold text-truncate text-decoration-none'
                },
                index: `title-${index}`,
                value: course.title
            })

            row.children.push({
                props: {
                    class: 'td-level text-truncate'
                },
                index: `levels-${index}`,
                value: _.join(_.map(_levels, o => o.title), ', ')
            })

            row.children.push({
                props: {
                    class: 'td-group text-truncate'
                },
                index: `groups-${index}`,
                value: _.join(_.map(_groups, o => o.title), ', ')
            })

            row.children.push({
                props: {
                    class: 'td-students-amount text-center'
                },
                index: `students-amount-${index}`,
                value: _.sumBy(_groups, 'students_count')
            })

            let _courseTotal = 0

            this.headerOfLessonTypes.forEach(item => {
                const lessonTypeUuid = item.lesson_type_uuid
                let _capacities = _.get(capacityCL, `${courseUuid}.${lessonTypeUuid}`, null)

                //Filter by study period
                const studyPeriod = this.filterBy.studyPeriod
                if(_capacities && studyPeriod) {
                    _capacities = _.filter(_capacities, o => o['study_period_uuid'] === studyPeriod)
                }

                const _capacityTotal = _capacities ? _.sumBy(_capacities, 'value') : null

                const ltClass = item.lt ? 'td-lesson-type--lt' : ''
                const rtClass = item.rt ? 'td-lesson-type--rt' : ''

                row.children.push({
                    props: {
                        class: `text-center ${ltClass}${rtClass}`
                    },
                    index: `capacity-${index}-${item.lesson_type_uuid}`,
                    value: _capacityTotal > 0 ? _capacityTotal : null
                })

                if(!_lessonTypesTotal[lessonTypeUuid]) {
                    _lessonTypesTotal[lessonTypeUuid] = {
                        props: {
                            class: `td-lesson-type-total-${lessonTypeUuid} text-center font-weight-semibold ${ltClass}${rtClass}`
                        },
                        index: `td-lesson-type-total-${lessonTypeUuid}`,
                        value: 0
                    }
                }

                if(_capacityTotal) {
                    _courseTotal += Number(_capacityTotal)
                    _lessonTypesTotal[lessonTypeUuid]['value'] += _capacityTotal
                }

            })

            row.children.push({
                props: {
                    class: 'td-total text-center font-weight-semibold '
                },
                index: `total-${index}`,
                value: _courseTotal
            })

            if(!_lessonTypesTotal[coursesTotalKey]) {
                _lessonTypesTotal[coursesTotalKey] = {
                    props: {
                        class: 'td-total text-center font-weight-semibold '
                    },
                    index: `td-${coursesTotalKey}-total`,
                    value: _courseTotal
                }
            } else {
                _lessonTypesTotal[coursesTotalKey]['value'] += _courseTotal
            }

            data.push(row)
        }

        const totalRow = {
            course: null,
            children: [
                {
                    props: {
                        class: 'td-number text-center'
                    },
                    index: 'td-lesson-type-total-number',
                    value: null
                },
                {
                    props: {
                        colspan: 4,
                        class: 'td-lesson-type-total-title text-left font-weight-semibold'
                    },
                    translate: true,
                    value: 'Total'
                }
            ]
        }

        for (const lessonTypesTotalKey in _lessonTypesTotal) {
            const td = _lessonTypesTotal[lessonTypesTotalKey]
            td.value = td.value > 0 ? td.value : null

            totalRow.children.push(td)
        }

        data.push(totalRow)

        return data
    }



}
