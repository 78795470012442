var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"capacity",attrs:{"tile":"","loading":_vm.isLoading,"min-height":"300px"}},[_c('div',{staticClass:"d-md-flex align-center pa-4"},[_c('div',{staticClass:"capacity-filter"},[_c('v-select',{attrs:{"hide-details":"","dense":"","outlined":"","full-width":"","items":_vm.modeSelectItems},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('w-icon',{staticClass:"mdi-view-carousel",attrs:{"dense":"","value":"PERIOD"}})]},proxy:true}]),model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}})],1),_c('div',{staticClass:"capacity-filter pl-md-4 pt-md-0 pt-4"},[_c('v-select',{attrs:{"placeholder":_vm.$trans('Level'),"outlined":"","clearable":"","dense":"","hide-details":"","full-width":"","items":_vm.levelsSelectItems,"item-text":"title","item-value":"uuid"},on:{"click:clear":function($event){_vm.service.filterBy.group = null}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('w-icon',{staticClass:"prepend-inner-icon",attrs:{"dense":"","value":"LEVEL"}})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"height":"2.5rem"},attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(item.school.title)+" ")])],1)],1)]}}]),model:{value:(_vm.service.filterBy.level),callback:function ($$v) {_vm.$set(_vm.service.filterBy, "level", $$v)},expression:"service.filterBy.level"}})],1),(_vm.mode === 'capacity')?_c('div',{staticClass:"capacity-filter pl-md-4 pt-md-0 pt-4"},[_c('v-select',{attrs:{"placeholder":_vm.$trans('Study period'),"hide-details":"","dense":"","outlined":"","clearable":"","full-width":"","items":_vm.studyPeriods},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('w-icon',{staticClass:"prepend-inner-icon",attrs:{"dense":"","value":"PERIOD"}})]},proxy:true}],null,false,4013783058),model:{value:(_vm.service.filterBy.studyPeriod),callback:function ($$v) {_vm.$set(_vm.service.filterBy, "studyPeriod", $$v)},expression:"service.filterBy.studyPeriod"}})],1):_vm._e(),(_vm.mode === 'capacity')?_c('div',{staticClass:"capacity-filter pl-md-4 pt-md-0 pt-4"},[(_vm.teacherSelectItems.length > 0)?_c('v-select',{attrs:{"placeholder":_vm.$trans('Teacher'),"outlined":"","clearable":"","dense":"","hide-details":"","full-width":"","items":_vm.teacherSelectItems,"item-text":"last_name","item-value":"uuid"},on:{"click:clear":function($event){_vm.service.filterBy.group = null}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('w-icon',{staticClass:"prepend-inner-icon",attrs:{"dense":"","value":"TEACHER"}})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-select__selection v-select__selection--comma"},[_vm._v(_vm._s(_vm._f("fullName")(item)))])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attr = ref.attr;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attr,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("fullName")(item)))])],1)],1)]}}],null,false,3939720901),model:{value:(_vm.service.filterBy.teacher),callback:function ($$v) {_vm.$set(_vm.service.filterBy, "teacher", $$v)},expression:"service.filterBy.teacher"}}):_vm._e()],1):_vm._e()]),_c('v-divider'),(!_vm.isLoading)?_c('div',{staticClass:"capacity-table-wrapper"},[_c('v-simple-table',{staticClass:"capacity-table"},[_c('thead',_vm._l((_vm.header),function(row,index){return _c('tr',{key:("thead-tr-" + index)},_vm._l((row),function(col){return _c('td',_vm._b({key:col.index},'td',col.props,false),[(col.translate)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans(col.value))+" ")]):_c('div',[_vm._v(" "+_vm._s(col.value)+" ")])])}),0)}),0),_c('tbody',_vm._l((_vm.rows),function(row,index){return _c('tr',{key:("thead-tr-" + index),class:index < (_vm.rows.length - 1) ? 'hover' : 'tr-total'},_vm._l((row.children),function(col){return _c('td',_vm._b({key:col.index},'td',col.props,false),[(col.translate)?[_vm._v(" "+_vm._s(_vm.$trans(col.value))+" ")]:[_vm._v(" "+_vm._s(col.value)+" ")]],2)}),0)}),0)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }